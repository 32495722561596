
<template>  
    <div class=''>会议记录</div>
 </template>
 
 <script>
 
 export default {
   components: {},
   data() {
     return {
   
     };
   },
 
   methods: {
   
   },
 
   created() {
   
   },
 
   mounted() {
   
   },
 
   }
 </script>
 <style lang='less' scoped>
 
 </style>